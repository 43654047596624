import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth/auth.service';
import { CadastrarCategoriaComponent } from './views/categoria/cadastrar/cadastrar.component';
import { CategoriaComponent } from './views/categoria/categoria.component';
import { EditarCategoriaComponent } from './views/categoria/editar/editar.component';
import { ClientesComponent } from './views/clientes/clientes.component';
import { EmpresaComponent } from './views/empresa/empresa.component';
import { LoginComponent } from './views/login/login.component';
import { PedidosComponent } from './views/pedidos/pedidos.component';
import { CadastroComponent } from './views/produto/cadastro/cadastro.component';
import { EditarComponent } from './views/produto/editar/editar.component';
import { ProdutoComponent } from './views/produto/produto.component';

const routes: Routes = [];

let login = { path: 'login', component: LoginComponent };
let produto = { path: 'produtos', component: ProdutoComponent, canActivate: [AuthGuard]  };
let home = { path: '', redirectTo: '/produtos', pathMatch: 'full' };
let produtoCadastro= { path: 'produtos/cadastro', component: CadastroComponent,  canActivate: [AuthGuard]  };
let produtoEditar= { path: 'produtos/editar/:id', component: EditarComponent,  canActivate: [AuthGuard]  };
let categoria= { path: 'categorias', component: CategoriaComponent,  canActivate: [AuthGuard]  };
let categoriaEditar= { path: 'categorias/editar/:id', component: EditarCategoriaComponent,  canActivate: [AuthGuard]  };
let categoriaCadastro= { path: 'categorias/cadastro', component: CadastrarCategoriaComponent,  canActivate: [AuthGuard]  };
let pedidos= { path: 'pedidos', component: PedidosComponent,  canActivate: [AuthGuard]  };
let clientes= { path: 'clientes', component: ClientesComponent,  canActivate: [AuthGuard]  };
let empresa= { path: 'empresa', component: EmpresaComponent,  canActivate: [AuthGuard]  };

routes.push(login);
routes.push(clientes);
routes.push(empresa);
routes.push(produto);
routes.push(produtoCadastro);
routes.push(home);
routes.push(produtoEditar);
routes.push(categoria);
routes.push(categoriaEditar);
routes.push(categoriaCadastro);
routes.push(pedidos);

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
