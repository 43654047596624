
<div>
<button style="margin-bottom: 5px; cursor: pointer;" type="button" class="btn btn-primary" routerLink="/produtos/cadastro" >Cadastrar</button>

    <div class="row">
      <div class="col-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Descrição</th>
              <th scope="col">Preço</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let produto of produtos; let i = index">
              <td>{{produto.name}}</td>
              <td>{{produto.desc}}</td>
              <td>{{produto.price | currency:'BRL'}}</td>
              <td style="min-width: 120px;">
                <button style="margin-right: 10px;" type="button" class="btn btn-primary" (click)="goToProductDetails(produto.id)"><i class="far fa-eye"></i></button>
                <button (click)="deleteProduct(produto.id)"type="button" class="btn btn-danger"><i class="far fa-trash-alt"></i></button>
              </td>
            </tr>           
          </tbody>
        </table>
      </div>
    </div>
  </div>