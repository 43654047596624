import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

import jwtDecode from 'jwt-decode';
import * as moment from 'moment';



@Injectable()
export class AuthService {
  private apiRoot = 'https://ph-cardapio.herokuapp.com/api/user/';

  constructor(private http: HttpClient) { }

  private setSession(authResult: any) {
    const token = authResult.token;
    const payload = <JWTPayload>jwtDecode(token);
    const expiresAt = moment.unix(payload.exp);

    localStorage.setItem('token', authResult.token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    localStorage.setItem('company', payload.Company);
    localStorage.setItem('companyName', payload.CompanyName);
  }

  get token(): string | null {
    return localStorage.getItem('token');
  }

  getCompany(){
    return localStorage.getItem('company');
  }
  
  getCompanyName(){
    return localStorage.getItem('companyName');
  }

  login(username: string, password: string) {
    return this.http.post(
      this.apiRoot.concat('login'),
      { username, password }
    ).pipe(
      tap(response => this.setSession(response)),
      shareReplay(),
    );
  }

  signup(username: string, email: string, password1: string, password2: string) {
    // TODO: implement signup
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('company');
  }

  refreshToken() {
    //if (moment().isBetween(this.getExpiration().subtract(1, 'days'), this.getExpiration())) {
    //  return this.http.post(
    //    this.apiRoot.concat('refresh-token/'),
    //    { token: this.token }
    //  ).pipe(
    //    tap(response => this.setSession(response)),
    //    shareReplay(),
    //  ).subscribe();
    //}
  }

  getExpiration() {
    const expiration = <string>localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);

    return moment(expiresAt);
  }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }
}



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');

    if (token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'JWT '.concat(token))
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {
      this.authService.refreshToken();

      return true;
    } else {
      this.authService.logout();
      window.location.href = '/login'

      return false;
    }
  }
}


interface JWTPayload {
  user_id: number;
  username: string;
  email: string;
  exp: number;
  Company: string;
  CompanyName: string;
}
