import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private _authService : AuthService) { }

  ngOnInit(): void {
  }

  logout(){
    this._authService.logout();
    window.location.href = '/login';
  }

  isMenuActivate(current : string) : boolean{
    if(window.location.pathname == current){
      return true;
    }
    return false;
  }


  getCompanyName(){
    return this._authService.getCompanyName();
  }

  getUrlLoja(){
    window.location.href = 'http://modelo.menu7.com.br';
  }






}
