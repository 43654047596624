<div class="wrapper fadeInDown">
    <div id="formContent">
      <!-- Tabs Titles -->
  
      <!-- Icon -->
      <div class="fadeIn first">
        <!-- <img src="http://danielzawadzki.com/codepen/01/icon.svg" id="icon" alt="User Icon" /> -->
        <p>Login</p>
      </div>
  
      <!-- Login Form -->
      <form [formGroup]="formulario">
        <input type="text" id="login" class="fadeIn second" name="login" placeholder="user" [formControlName]="'username'">
        <input type="password" id="password" class="fadeIn third" name="login" placeholder="password" [formControlName]="'password'">
        <input type="submit" class="fadeIn fourth" value="Log In" (click)="submitForm()">
      </form>
  
      <!-- Remind Passowrd -->
      <!-- <div id="formFooter">
        <a class="underlineHover" href="#">Forgot Password?</a>
      </div> -->
  
    </div>
  </div>