import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Categoria } from 'src/app/models/categoria';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CategoriaService } from 'src/app/services/categoria/categoria.service';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})
export class CategoriaComponent implements OnInit {
  public categorias: Categoria[] = [];
  constructor(private _authSeervice : AuthService, private _router : Router, private _categoriaService : CategoriaService ) { }

  ngOnInit(): void {
    this.getCategorias();
  }

  getCategorias(){
    this._categoriaService.obterCategoria(this._authSeervice.getCompany()).subscribe(
      resultado => {
        this.categorias = resultado
      }
    );
  }

  goToCategoryDetails(id : any) {
    this._router.navigate(['/categorias/editar', id]);
  }

  deleteCategory(id : any) {
    this.categorias = this.categorias.filter(x => x.id != id);
    this._categoriaService.deleteCategoria(id)
    .subscribe(
      () => {
        
      }
    )
  }
}
