import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formulario: FormGroup;
  error: any;

  constructor(private readonly fb: FormBuilder, private authService: AuthService,
    private router: Router) {
    this.formulario= this.fb.group({
      username: [],      
      password: []
    });
   }

  ngOnInit(): void {
  }

  submitForm() {
    let username = this.formulario.controls['username'].value;
    let password = this.formulario.controls['password'].value;

    this.authService.login(username, password).subscribe(
      success => window.location.href = '/produtos',
      error => this.error = error
    );
  }

}
