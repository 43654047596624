  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <a class="navbar-brand" href="#">{{getCompanyName()}}</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [class.active]="isMenuActivate('/produtos')">
          <a class="nav-link" href="/produtos">Produtos</a>
        </li>
        <li class="nav-item" [class.active]="isMenuActivate('/categorias')">
          <a class="nav-link" href="/categorias">Categorias</a>
        </li>
        <li class="nav-item" [class.active]="isMenuActivate('/pedidos')">
          <a class="nav-link" href="/pedidos">Pedidos</a>
        </li>
        <li class="nav-item" [class.active]="isMenuActivate('/clientes')">
          <a class="nav-link" href="/clientes">Clientes</a>
        </li>
        <li class="nav-item" [class.active]="isMenuActivate('/empresa')">
          <a class="nav-link" href="/empresa">Empresa</a>
        </li>
      </ul>
      <span class="navbar-text" (click)="getUrlLoja()"style="cursor: pointer;">
        Ver loja
      </span>
      <span style="color: #FFF; margin-left: 5px; margin-right: 5px;">|</span>
      <span class="navbar-text" (click)="logout()" style="cursor: pointer;">
        Sair
      </span>
    </div>
  </nav>