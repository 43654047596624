import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderResponse } from 'src/app/models/orderResponse';
import { paginationResult } from 'src/app/models/pagination/paginationResult';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private apiRoot = 'https://ph-cardapio.herokuapp.com/api/order';
  constructor(private http: HttpClient, private _authService: AuthService) { }

  obterPedidos(page : number) : Observable<paginationResult<OrderResponse>>{
    return this.http.get<paginationResult<OrderResponse>>(
      this.apiRoot + '/company/' + this._authService.getCompany() +"?limit=5&page="+page
    );
  }
}
