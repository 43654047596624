<div>
    <form [formGroup]="produto" (ngSubmit)="submitForm()">
        <div class="card">
            <div class="card-header">
                Produto
            </div>
            <div class="card-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3">
                            <img class="img-fluid"
                                src="{{imageBase64}}">
                        </div>
                        <div class="col-md-9 ">
                            <div class="row">
                                <div class="col-md-6 col-margin">
                                    <input type="text" class="form-control" placeholder="Nome" formControlName="name" [ngClass]="{ 'invalid': submitted && form.name.errors }">
                                </div>
                                <div class="col-md-6 col-margin">
                                    <select class="form-control" id="exampleFormControlSelect1"
                                        formControlName="categoryId" placeholder="Categoria" [ngClass]="{ 'invalid': submitted && form.categoryId.errors }">
                                        <option *ngFor="let categoria of categorias; let i = index"
                                            [value]="categorias[i].id">
                                            {{categorias[i].name}}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-md-12 col-margin">
                                    <textarea placeholder="Descrição" formControlName="desc" class="form-control"
                                        id="exampleFormControlTextarea1" rows="3"  [ngClass]="{ 'invalid': submitted && form.desc.errors }"></textarea>
                                </div>
                                <div class="col col-margin">
                                    <input currencyMask  type="text" [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }" [ngClass]="{ 'invalid': submitted && form.price.errors }" class="form-control" placeholder="Preço" formControlName="price">
                                </div>
                                <div class="col col-margin">
                                    <select class="form-control" id="exampleFormControlSelect1" placeholder="Status"
                                        formControlName="productStatus"  [ngClass]="{ 'invalid': submitted && form.productStatus.errors }">
                                        <option value="1">Ativo</option>
                                        <option value="0">Inativo</option>
                                    </select>
                                </div>
                                <div class="col-md-12 col-margin">
                                    <input id="upload" type="file" (change)="handleUpload($event)"
                                        class="form-control border-0">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div formArrayName="group">
            <div class="row">
                <div class="col-md-3">
                    <button (click)="addGrupo()" type="button" class="btn btn-primary"
                    style="margin-bottom: 10px; margin-top: 30px;">Add Grupo</button>
                </div>

                <div class="offset-md-7 col-md-2">
                    <button (click)="submitForm()" type="button" class="btn btn-primary" style="width: 100%; margin-bottom: 10px; margin-top: 30px;">Enviar</button>
                </div>
              </div>


            <div *ngFor="let grupo of getGrupo(produto); let i=index">
                <div style="margin: 5px;" [formGroupName]="i">
                    <div id="g1">
                        <span>Grupo {{ i +1 }}</span>
                        <div class="row line">
                            <div class="col-md-7">
                                <input type="text" class="form-control" placeholder="Nome do Grupo"
                                    formControlName="name" [ngClass]="{ 'invalid': submitted && fgroup(i,'name') }">
                            </div>
                            <div class="col-md-2">
                                <select class="form-control" id="exampleFormControlSelectw"
                                    placeholder="Quantidade Minima" formControlName="qtdMin"  [ngClass]="{ 'invalid': submitted && fgroup(i,'qtdMin') }">
                                   
                                    <option *ngFor="let com of getTotalComplemento(i); let k = index" [value]="k">
                                    {{k}}
                                    </option>
                       
                                </select>
                            </div>
                            <div class="col-md-2">
                                <select class="form-control" id="exampleFormControlSelectw"
                                placeholder="Quantidade Máxima" formControlName="qtdMax"  [ngClass]="{ 'invalid': submitted && fgroup(i,'qtdMax') }">
                               
                                <option *ngFor="let com of getTotalComplemento(i); let k = index" [value]="k">
                                {{k}}
                                </option>
                   
                            </select>
                            </div>
                            <div class="col-md-1">
                                <button (click)="removerGrupo(i)" type="button"
                                    class="btn btn-danger float-right">-</button>
                            </div>
                        </div>
                    </div>

                    <div formArrayName="complement">
                        <div *ngFor="let comp of getComplemento(grupo); let j=index">
                            <div [formGroupName]="j">
                                <span>Complemento {{ j + 1 }}</span>
                                <div class="row line">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Nome do Complemento"
                                            formControlName="name"  [ngClass]="{ 'invalid': submitted && fcomponent(i,j,'name') }">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Descricao"
                                            formControlName="desc">
                                    </div>
                                    <div class="col">
                                        <input currencyMask type="text" class="form-control" placeholder="Preço"
                                            formControlName="price"  [options]="{ align: 'left', prefix: 'R$ ', thousands: '.', decimal: ',' }" [ngClass]="{ 'invalid': submitted && fcomponent(i,j,'price') }">
                                    </div>
                                    <div class="col">
                                        <select class="form-control" id="exampleFormControlSelect1" placeholder="Status"
                                            formControlName="complementStatus"  [ngClass]="{ 'invalid': submitted && fcomponent(i,j,'complementStatus') }">
                                            <option value="1">Ativo</option>
                                            <option value="0">Inativo</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <button (click)="removerComplemento(i, j)" type="button"
                                            class="btn btn-danger float-left">-</button>

                                        <button *ngIf="j<1" (click)="addComplemento(i)" type="button"
                                            class="btn btn-primary float-right">+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="margin-top: 20px; margin-bottom: 20px;">
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="getTotalGrupo() > 0" class="col-md-3 offset-md-9" style="margin-bottom: 20px;">
            <button (click)="submitForm()" type="button" class="btn btn-primary" style="width: 100%;">Enviar</button>
        </div>
    </form>
</div>

<!-- <div>
    {{produto.value | json}}
</div>  -->