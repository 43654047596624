import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  public isLogin : boolean = false;

  title = 'Cardápio Admin';

  constructor(private router: Router, private route: ActivatedRoute) { }
  
  ngOnInit(): void {
    this.isLogin = window.location.pathname === '/login';
  }
}


