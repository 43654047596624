import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CategoriaService } from 'src/app/services/categoria/categoria.service';

@Component({
  selector: 'app-cadastrar',
  templateUrl: './cadastrar.component.html',
  styleUrls: ['./cadastrar.component.css']
})
export class CadastrarCategoriaComponent implements OnInit {
  categoriaForm: FormGroup;
  submitted = false;

  constructor(private authService: AuthService, private _router : Router, private readonly fb: FormBuilder, private _categoriaService : CategoriaService) { 
    this.categoriaForm = this.fb.group({
      companyId: [this.authService?.getCompany()],
      name: ['', Validators.required],
      desc: [''],
    })
  }

  ngOnInit(): void {

  }
  submitForm() {
    this.submitted = true;

    if (this.categoriaForm.invalid) {
      return;
    }

    this._categoriaService.salvarCategoria(this.categoriaForm.value).subscribe(
      resultado => {
        this._router.navigate(['/categorias']);
      }
    );
  }

  get form() {
    return this.categoriaForm.controls;
  }

}
