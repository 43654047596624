<div>
    <h3>Categoria</h3>
    <form [formGroup]="categoriaForm" (ngSubmit)="submitForm()">
    <div class="row">
        <div class="col">
          <input type="text" class="form-control" placeholder="Nome" formControlName="name" [ngClass]="{ 'invalid': submitted && form.name.errors}">
        </div>
        <div class="col">
          <input type="text" class="form-control" placeholder="Descrição" formControlName="desc"  [ngClass]="{ 'invalid': submitted && form.desc.errors}">
        </div>
        <div class="col">
            <button type="submit" class="btn btn-primary mb-2">Enviar</button>
          </div>
      </div>
    </form>
</div>