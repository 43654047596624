import { FormatWidth } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Categoria } from 'src/app/models/categoria';
import { Complement } from 'src/app/models/complement';
import { Group } from 'src/app/models/group';
import { Produto } from 'src/app/models/produto';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CategoriaService } from 'src/app/services/categoria/categoria.service';
import { ProdutoService } from 'src/app/services/produto/produto.service';


@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.css']
})
export class EditarComponent implements OnInit {
  baseImageUrl = 'https://safood.blob.core.windows.net/produtos';
  companyId = 1;
  produto: FormGroup;
  produtoBD: Produto = <Produto>{};
  categorias: Categoria[] = [];
  imageBase64: any = '';
  showGroup = false;
  submitted = false;

  constructor(private _authSeervice : AuthService, private _acrouter: ActivatedRoute, private _router: Router, private readonly fb: FormBuilder, private _categoriaService: CategoriaService, private _produtoService: ProdutoService) {
    this.produto = this.fb.group({
      companyId: _authSeervice.getCompany(),
      product: 1,
      name: ['', Validators.required],
      categoryId: [0, Validators.required],
      image: [null, Validators.required],
      desc: ['', Validators.required],
      price: ['', Validators.required],
      productStatus: ['', Validators.required],
    });
    this.getCategorias();
  }

  ngOnInit(): void {
    const id = this._acrouter.snapshot.paramMap.get('id');
    this.updateProduto(id);
  }


  updateProduto(id: any) {
    this._produtoService.obterProduto(id).subscribe(
      resultado => {
        this.produtoBD = resultado;
        this.produto = this.fb.group({
          companyId:  this._authSeervice.getCompany(),
          name:  [this.produtoBD.name, Validators.required],
          categoryId: [this.produtoBD.categoryId, Validators.required],
          image: [null],
          desc: this.produtoBD.desc,
          price: [this.produtoBD.price, Validators.required],
          productStatus: [this.produtoBD.productStatus, Validators.required],
        })

        this.produto.setControl('group', this.setGrupoArray(this.produtoBD.group));
        this.showGroup = true;
      }
    )
  }

  get form() {
    return this.produto.controls;
  }

  fgroup(i: number, name: string) {
    if (this.submitted) {
      let a = (<FormArray>(this.produto.get('group') as FormArray).controls[i]).get(name)?.errors
      return a;
    }
    return;
  }


  fcomponent(ig: number, ic: number, name: string) {
    if (this.submitted) {
      let a = (<FormArray>(this.produto.get('group') as FormArray).controls[ig]);
      let b = (<FormArray>(a.get('complement') as FormArray).controls[ic]).get(name)?.errors;
      return b;
    }
    return;
  }
 

  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.produto.patchValue({
        image: reader.result
      });
    };
  }

  getGrupo(form: any) {
    return (form.controls.group as FormArray).controls;
  }

  getComplemento(form: any) {
    return (form.controls.complement as FormArray).controls;
  }

  createForm() {
    this.produto = this.fb.group({
      grupo: this.fb.array([this.createGrupoArray()])
    })
  }


  setGrupoArray(groups: Group[]): FormArray {
    const gFormArray = new FormArray([]);
    groups.forEach(g => {
      gFormArray.push(this.fb.group({
        name: [g.name, Validators.required],
        qtdMin: [g.qtdMin, Validators.required],
        qtdMax: [g.qtdMax, Validators.required],
        complement: this.setComplementArray(g.complement)
      }));
    });
    return gFormArray;
  }


  setComplementArray(complement: Complement[]): FormArray {
    const cFormArray = new FormArray([]);
    complement.forEach(c => {
      cFormArray.push(this.fb.group({
        name: [c.name, Validators.required],
        desc: c.desc,
        price: c.price,
        complementStatus: [c.complementStatus, Validators.required]
      }));
    });
    return cFormArray;
  }

  createGrupoArray() {
    return this.fb.group({
      name: ['', Validators.required],
      qtdMin: ['', Validators.required],
      qtdMax: ['', Validators.required],
      complement: new FormArray([
        this.createComplementoArray()
      ])
    });
  }

  createComplementoArray() {
    return this.fb.group({
      name: ['', Validators.required],
      desc: [''],
      price: ['', Validators.required],
      complementStatus: ['', Validators.required]
    })
  }


  addGrupo() {
    const control = <FormArray>this.produto.get('group');
    control.push(this.createGrupoArray());
  }

  addComplemento(i: any) {
    const control = <FormArray>(this.produto.get('group') as FormArray).controls[i].get('complement');
    control.push(this.createComplementoArray());
  }

  submitForm() {
    this.submitted = true;

    if (this.produto.invalid) {
      return;
    }
    let produtoPut = <Produto>this.produto.value;
    produtoPut.id = this.produtoBD.id;
    this._produtoService.atualizarProduto(produtoPut).subscribe(
      resultado => {
        this._router.navigate(['/produtos']);
      }
    );
  }
  removerComplemento(group: number, complement: number) {
    const control = <FormArray>(this.produto.get('group') as FormArray).controls[group].get('complement');
    control.removeAt(complement);
  }

  getTotalComplemento(group: number) {
    const control = <FormArray>(this.produto.get('group') as FormArray).controls[group].get('complement');
    return Array(control.length + 1);
  }
  removerGrupo(group: number) {
    const control = <FormArray>(this.produto.get('group') as FormArray);
    control.removeAt(group);
  }

  getTotalGrupo() : number {
    const control = <FormArray>(this.produto.get('group') as FormArray);
    return control?.length;
  }

  getCategorias() {
    this._categoriaService.obterCategoria(this._authSeervice.getCompany()).subscribe(
      resultado => {
        this.categorias = resultado
      }
    );
  }

}
