import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Categoria } from 'src/app/models/categoria';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CategoriaService } from 'src/app/services/categoria/categoria.service';
import { ProdutoService } from 'src/app/services/produto/produto.service';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent implements OnInit {
  produto: FormGroup;
  categorias: Categoria[] = [];
  imageBase64 : any = '';
  submitted = false;


  constructor(private _authSeervice : AuthService, private _router : Router, private readonly fb: FormBuilder, private _categoriaService : CategoriaService, private _produtoService : ProdutoService) {
    this.produto = this.fb.group({
      companyId: _authSeervice.getCompany(),
      name: ['', Validators.required],
      categoryId: [0, Validators.required],
      image: [null],
      desc: [''],
      price: ['', Validators.required],
      productStatus: ['', Validators.required],
      group: this.fb.array([])
    })
    this.getCategorias();
   }

   ngOnInit(): void {
     console.log(this._authSeervice.getCompany());
   }

   get form() {
    return this.produto.controls;
  }

  fgroup(i: number, name: string) {
    if (this.submitted) {
      let a = (<FormArray>(this.produto.get('group') as FormArray).controls[i]).get(name)?.errors
      return a;
    }
    return;
  }


  fcomponent(ig: number, ic: number, name: string) {
    if (this.submitted) {
      let a = (<FormArray>(this.produto.get('group') as FormArray).controls[ig]);
      let b = (<FormArray>(a.get('complement') as FormArray).controls[ic]).get(name)?.errors;
      return b;
    }
    return;
  }

   handleUpload(event : any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.produto.patchValue({
        image: reader.result,
      });
      this.imageBase64 = reader.result;
    };
  }

  getGrupo(form : any) {
    return (form.controls.group as FormArray).controls;
  }

  getComplemento(form : any) {
    return (form.controls.complement as FormArray).controls;
  }

   createForm() {
    this.produto = this.fb.group({
      grupo: this.fb.array([this.createGrupoArray()])
    })
  }

  createGrupoArray() {
    return this.fb.group({
      name: ['', Validators.required],
      qtdMin: ['', Validators.required],
      qtdMax: ['', Validators.required],
      complement: new FormArray([
        this.createComplementoArray()
      ])
    });
  }

  createComplementoArray() {
    return this.fb.group({
      name: ['',Validators.required],
      desc: [''],
      price: ['',Validators.required],
      complementStatus: ['',Validators.required]
    })
  }


  addGrupo(){
    const control = <FormArray>this.produto.get('group');
    control.push(this.createGrupoArray());
  }

  addComplemento(i : any){
    const control = <FormArray>(this.produto.get('group') as FormArray).controls[i].get('complement');
    control.push(this.createComplementoArray());
  }

  submitForm() {
    this.submitted = true;

    if (this.produto.invalid) {
      return;
    }

    this._produtoService.cadastrarProduto(this.produto.value).subscribe(
      resultado => {
        this._router.navigate(['/produtos']);
      }
    );
  }
  removerComplemento(group: number, complement: number) {
    const control = <FormArray>(this.produto.get('group') as FormArray).controls[group].get('complement');
    control.removeAt(complement);
  }

  getTotalComplemento(group: number) {
    const control = <FormArray>(this.produto.get('group') as FormArray).controls[group].get('complement');
    return Array(control.length + 1);
  }

  getTotalGrupo() : number {
    const control = <FormArray>(this.produto.get('group') as FormArray);
    return control?.length;
  }

  removerGrupo(group: number) {
    const control = <FormArray>(this.produto.get('group') as FormArray);
    control.removeAt(group);
  }

  getCategorias(){
    this._categoriaService.obterCategoria(this._authSeervice.getCompany()).subscribe(
      resultado => {
        this.categorias = resultado
      }
    );
  }

}
