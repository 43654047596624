import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Produto } from 'src/app/models/produto';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProdutoService } from 'src/app/services/produto/produto.service';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.scss']
})
export class ProdutoComponent implements OnInit {
  public produtos: Produto[] = [];
  company : any = 0;

  constructor(private _authSeervice : AuthService, private _router : Router, private _produtoService: ProdutoService) { }

  ngOnInit(): void {
    this.company = this._authSeervice.getCompany();
    this._produtoService.obterProdutos(this.company)
      .subscribe(
        resultado => {
          this.produtos = resultado
        }
      )
  }

  goToProductDetails(id : any) {
    this._router.navigate(['/produtos/editar', id]);
  }

  deleteProduct(id : any) {
    this.produtos = this.produtos.filter(x => x.id != id);
    this._produtoService.deletarProduto(id)
    .subscribe(
      result => {

      }
    )
  }

}
