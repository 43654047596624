import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './shared/menu/menu.component';
import { ProdutoComponent } from './views/produto/produto.component';
import { LoginComponent } from './views/login/login.component';
import { MenuSideComponent } from './shared/menu-side/menu-side.component';
import { CadastroComponent } from './views/produto/cadastro/cadastro.component';
import { PedidosComponent } from './views/pedidos/pedidos.component';
import { ClientesComponent } from './views/clientes/clientes.component';
import { ReportsComponent } from './views/reports/reports.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard, AuthInterceptor, AuthService } from './services/auth/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EditarComponent } from './views/produto/editar/editar.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CategoriaComponent } from './views/categoria/categoria.component';
import { CadastrarCategoriaComponent } from './views/categoria/cadastrar/cadastrar.component';
import { EditarCategoriaComponent } from './views/categoria/editar/editar.component';
import { EmpresaComponent } from './views/empresa/empresa.component';
import { NgxPaginationModule } from 'ngx-pagination';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr)

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ProdutoComponent,
    LoginComponent,
    MenuSideComponent,
    CadastroComponent,
    PedidosComponent,
    ClientesComponent,
    ReportsComponent,
    EditarComponent,
    CategoriaComponent,
    CadastrarCategoriaComponent,
    EditarCategoriaComponent,
    EmpresaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxCurrencyModule,
    NgxPaginationModule,
    NgHttpLoaderModule.forRoot(), // <============ Don't forget to call 'forRoot()'!
  ],
  providers: [
    AuthService,
    [{ provide: LOCALE_ID, useValue: 'pt' }],
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
