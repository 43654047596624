import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Categoria } from 'src/app/models/categoria';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {
  private apiRoot = 'https://ph-cardapio.herokuapp.com';
  constructor(private http: HttpClient) { }
  
  obterCategoria(companyId : any) : Observable<Categoria[]>{
    return this.http.get<Categoria[]>(
      this.apiRoot + '/category/company/' + companyId
    );
  }

  salvarCategoria(categoria : Categoria) : Observable<Categoria>{
    return this.http.post<Categoria>(
      this.apiRoot + '/api/category',
      categoria
    );
  }

  atualizarCategoria(categoria : Categoria) : Observable<Categoria>{
    return this.http.patch<Categoria>(
      this.apiRoot + '/api/category',
      categoria
    );
  }

  obterCategoriaPorId(id : number) : Observable<Categoria>{
    return this.http.get<Categoria>(
      this.apiRoot + "/api/category/id/"+ id
    );
  }

  deleteCategoria(id : number) {
    return this.http.delete<Categoria>(
      this.apiRoot +"/api/category?id="+id,
    );
  }
}
