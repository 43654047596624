
<div class="col-12">
<h5>Pedidos</h5>
    <table class="table table-condensed" id="myTable">
        <thead>
            <tr>
                <th scope="col">Nome</th>
                <th scope="col">Celular</th>
                <th scope="col">Cep</th>
                <th scope="col">Rua</th>
                <th scope="col">Numero</th>
                <th scope="col">Complemento</th>
                <th scope="col">Pagamento</th>
                <th scope="col">Data</th>
            </tr>
        </thead>
        <tbody class="panel">
            <ng-container *ngFor="let order of orders | paginate: { itemsPerPage: 5, currentPage: page, totalItems: total }; let i = index">
            <tr data-toggle="collapse"  [attr.data-target]="'#demo2'+ i" data-parent="#myTable">
                <td>{{order.nome}}</td>
                <td>{{order.celular}}</td>
                <td>{{order.cep}}</td>
                <td>{{order.rua}}</td>
                <td>{{order.numero}}</td>
                <td>{{order.complemento}}</td>
                <td>{{getPagamento(order.pagamento)}}</td>
                <td>{{getDate(order.dataCriacao) | date:'dd/MM/yyyy HH:mm:ss'}}</td>
            </tr>

            <tr id="{{'demo2' + i}}" class="collapse">
                <td colspan="12" class="hiddenRow">
                    <div *ngFor="let product of order.orderProduct; let ind = index">
                       <p>1x {{product.descricao}} <span>Total: {{product.valorTotal}}</span></p>
                    </div>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
    <pagination-controls 
        class="my-pagination"
        (pageChange)="pageChanged($event)"
        previousLabel="Anterior"
        nextLabel="Próxima"
        screenReaderPaginationLabel="Pagination"
        screenReaderPageLabel="page"
        screenReaderCurrentLabel="You're on page">
    </pagination-controls>
</div>