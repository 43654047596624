<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css" integrity="sha512-HK5fgLBL+xu6dm/Ii3z4xhlSUyZgTT9tuc/hSrtw6uzJOvgRr2a9jyxxT1ely+B+xFAmJKVSTbpM/CuL7qxO8w==" crossorigin="anonymous" />
<div *ngIf="!isLogin">
  <app-menu></app-menu>
  <app-menu-side></app-menu-side>
  <div class="col-md-12" style="padding-top: 5vh;">
    <ng-http-loader></ng-http-loader>
    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="isLogin">
  <ng-http-loader></ng-http-loader>
  <router-outlet></router-outlet>
</div>