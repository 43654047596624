import { Component, OnInit } from '@angular/core';
import { OrderResponse } from 'src/app/models/orderResponse';
import { OrderService } from 'src/app/services/order/order.service';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {
  page: number = 1;
  total: number = 1;

  public orders: OrderResponse[] = [];
 
  constructor(private _orderService : OrderService) { }

  ngOnInit(): void {
    moment.locale('pt-br');
    registerLocaleData(ptBr)
    this.obterPedidos();
  }

  obterPedidos(){
    this._orderService.obterPedidos(this.page).subscribe(x => {
      this.orders = x.data;
      this.total = x.totalItems;
    })
  }

  pageChanged($event : any){
    this.page = $event;
    this.obterPedidos();
  }

  getPagamento(tipoPagamento : string) : any{
    if(tipoPagamento === "1")
      return "Dinheiro";

    return "Cartão";
  }

  getDate(data : Date): Date{
    var testDateUtc = moment.utc(data);
    var localDate = testDateUtc.local();
    return localDate.toDate();
  }

}
