import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Produto } from 'src/app/models/produto';

@Injectable({
  providedIn: 'root'
})

export class ProdutoService {
  private apiRoot = 'https://ph-cardapio.herokuapp.com/api/product';
  constructor(private http: HttpClient) { }

  obterProdutos(id : any) : Observable<Produto[]>{
    return this.http.get<Produto[]>(
      this.apiRoot + '/short/company/' + id
    );
  }

  obterProduto(id : any) : Observable<Produto>{
    return this.http.get<Produto>(
      this.apiRoot + '/short/' + id
    );
  }

  cadastrarProduto(produto : Produto) : Observable<Produto>{
    return this.http.post<Produto>(
      this.apiRoot,
      produto
    );
  }

  atualizarProduto(produto : Produto) : Observable<Produto>{
    return this.http.put<Produto>(
      this.apiRoot,
      produto
    );
  }

  deletarProduto(id : number) : Observable<Produto>{
    return this.http.delete<any>(
      this.apiRoot + "/" + id,
    );
  }
}
