import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Categoria } from 'src/app/models/categoria';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CategoriaService } from 'src/app/services/categoria/categoria.service';

@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.css']
})
export class EditarCategoriaComponent implements OnInit {
  categoriaForm: FormGroup;
  categoria: Categoria | null = null;
  submitted = false;

  constructor(private authService: AuthService, private _acrouter: ActivatedRoute, private _router : Router, private readonly fb: FormBuilder, private _categoriaService : CategoriaService) { 
    this.categoriaForm = this.fb.group({
      name: ['', Validators.required],
      desc: [''],
    })
  }

  ngOnInit(): void {
    const id = this._acrouter.snapshot.paramMap.get('id');
    this.categoria = this.getCategoria(id);
  }
  submitForm() {
    this.submitted = true;

    if (this.categoriaForm.invalid) {
      return;
    }

    this._categoriaService.atualizarCategoria(this.categoriaForm.value).subscribe(
      () => {
        this._router.navigate(['/categorias']);
      }
    );
  }

  getCategoria(id : any) : Categoria | null{
    let categoria : Categoria | null = null;
    this._categoriaService.obterCategoriaPorId(id).subscribe(
      (resultado) => {
        categoria = resultado;
        this.categoriaForm = this.fb.group({
          id: [categoria?.id],
          companyId: [this.authService?.getCompany()],
          name: [categoria?.name, Validators.required],
          desc: [categoria?.desc],
        })
      }
    );

    return categoria;
  }

  get form() {
    return this.categoriaForm.controls;
  }
}
